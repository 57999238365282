import { css } from "@emotion/core"
import mediaQueryGenerator from "../utils/mediaQGen"

const [mediaMin40em, mediaMax46em, mediaMax51Em] = mediaQueryGenerator([
  { type: "min", size: "40" },
  { type: "max", size: "46" },
  { type: "max", size: "50.75" },
])

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 6rem;
    ${mediaMin40em} {
      flex-direction: row-reverse;
    }
    ${mediaMax46em} {
      margin-top: 6rem;
    }
  `,
  image: css`
    position: relative;
    margin-top: 10rem;
    margin-left: 6rem;
    border-radius: 50% 50% 75% 25% / 29% 74% 26% 71%;

    ${mediaMax46em} {
      text-align: center;
      align-self: center;
      margin-top: 1rem;
      margin-left: 2rem;
      margin-right: 3rem;
      width: 320px !important;
      height: 350px !important;
      border-radius: 40px;
    }
  `,
  aboutSection: css`
    color: #000;
    font-size: 1.2rem;
  `,
  aboutContainer: css`
    margin: 0 auto;
    padding: 0 3rem;
    max-width: 800px;
  `,
  aboutContent: css`
    padding-top: 10vh;
    ${mediaMax46em} {
      padding-top: 2vh;
    }
  `,
  hTag: css`
    text-transform: uppercase;
    font-size: 2.4rem;
    line-height: 1.2em;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    display: inline-block;
    position: relative;
    &:not(:first-of-type) {
      padding-top: 5vh;
    }
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      background: #000;
      height: 7px;
      width: 7rem;
    }
  `,
  aboutShadow: css`
    color: rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 20%;
    left: 20%;
    font-size: 12rem;
    font-weight: 900;
    width: 200vw;
    text-transform: capitalize;
  `,
}

export default styles
