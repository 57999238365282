import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"

import aboutMe from "../contentJSON/about-me.json"
import LayoutContactMe from "../components/layout/layout-contact-me"
import styles from "../css/aboutme.css"

// could re-add later
/* {
     "subtitle": "What can I do for you",
     "content": "<ul> <li> <b>Software development </b> - as I love writing code, I would like to do it for you. I can help on big or small projects or start things from scratch. I am used to working with teams both on-site or in a distributed setup. </li> <li> <b> Frontend Architecture & Setup </b> - being one of my favorite things, I can set up frontend applications and do it right - using the best tools available and the latest best practices.   </li>   <li> <b> Technical consultance </b> - I can assist with a range of topics from software architecture to UI&UX, code quality, reviews, automated testing and CI/CD.  </li> </ul>"
   } */

export default ({ data: { file } }) => {
  return (
    <>
      <Helmet>
        <title>About Me</title>
      </Helmet>
      <LayoutContactMe bgClassName="aboutme">
        {() => (
          <div css={styles.container}>
            <Img
              css={styles.image}
              fixed={file.childImageSharp.fixed}
              alt="Alex's photo"
            ></Img>
            <section css={styles.aboutSection}>
              <div css={styles.aboutContainer}>
                <div css={styles.aboutContent}>
                  {/* <span css={styles.aboutShadow}>About me</span> */}
                  <>
                    {aboutMe.map(({ subtitle, content }) => (
                      <Fragment key={subtitle}>
                        <h2
                          css={styles.hTag}
                          dangerouslySetInnerHTML={{ __html: subtitle }}
                        ></h2>
                        <p dangerouslySetInnerHTML={{ __html: content }}></p>
                      </Fragment>
                    ))}

                    <h2 css={styles.hTag}> Blog </h2>
                    <p>
                      If you are interested in software development practices,
                      React, Javascript, programming trends you might like my
                      blog! Check it out{" "}
                      <a
                        href="https://blog.alexandrudanpop.dev/"
                        target="_blank"
                      >
                        here
                      </a>{" "}
                      or on{" "}
                      <a href="https://dev.to/alexandrudanpop/" target="_blank">
                        Dev.to
                      </a>
                      .
                    </p>
                  </>
                </div>
              </div>
            </section>
          </div>
        )}
      </LayoutContactMe>
    </>
  )
}

export const query = graphql`
  {
    file(name: { eq: "me1" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
